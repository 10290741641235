<template>
  <div class="article-box" v-loading="loading">
    <h1>证书打印</h1>
    <el-form
      :inline="true"
      :model="searchData"
      style="margin-top: 20px;"
    >
      <el-form-item>
        <el-input
          v-model="searchData.name"
          size="medium"
          style="width: 250px"
          placeholder="请输入单位全称或账号"
        ></el-input>
      </el-form-item>
      <el-form-item label="本机构等级:">
        <el-select v-model="searchData.plenaryLevel"  clearable placeholder="请选择">
          <el-option
            v-for="item in creatorList"
            :key="item.value"
            :label="item.name"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="是否在有效期:">
        <el-select
          v-model="searchData.state"
          clearable
          style="width: 130px"
          placeholder="请选择状态"
        >
          <el-option label="是" :value="1"></el-option>
          <el-option label="否" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          size="medium"
          icon="el-icon-search"
          @click="search"
          >搜索</el-button
        >
      </el-form-item>
    </el-form>
    <div style="margin-top: 20px">
      <el-table
        border
        :data="articleList"
        highlight-current-row
        header-row-class-name="table-header-class"
        :header-cell-style="{ 'background-color': '#F7F8FA' }"
        style="width: 100%;"
      >
        <el-table-column prop="title" type="index" label="序号">
        </el-table-column>
        <el-table-column prop="loginName" label="账号（企业信用代码）">
        </el-table-column>

        <el-table-column prop="unitName" label="单位全称"> </el-table-column>

        <el-table-column prop="plenaryLevel" label="本机构等级" >
          <template slot-scope="scope">
            <span v-if="scope.row.plenaryLevel === '1'">会员</span>
            <span v-if="scope.row.plenaryLevel === '2'">理事</span>
            <span v-if="scope.row.plenaryLevel === '3'">常务理事</span>
            <span v-if="scope.row.plenaryLevel === '4'">副会长</span>
          </template>
        </el-table-column>
        <el-table-column prop="isValid" label="是否在有效期" >
          <template slot-scope="scope">
            <span v-if="scope.row.isValid === 1">是</span>
            <span v-if="scope.row.isValid === 2">否</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="certificateExpireTime"
          label="证书到期时间"
        >
          <template slot-scope="scope">
            {{scope.row.certificateExpireTime+ '-12-31'}}
          </template>
        </el-table-column>

        <el-table-column width="230" label="操作" >
          <template slot-scope="scope">
            <span class="text-btn" @click="detail(scope.row, 1)"
              >单位证书打印</span
            >
            <span class="text-btn" @click="detail(scope.row, 3)"
              >导出邮寄地址</span
            >
            <span class="text-btn" @click="detail(scope.row, 2)"
              >单位代表证书打印</span
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        style="text-align: right; padding-top: 20px"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[10, 20, 30, 40, 50, 60, 70, 80]"
        layout="total, sizes, prev, pager, next, jumper"
        :page-size="pageSize"
        :current-page="pageNo"
        :page-count="totalPage"
        :total="totalCount"
      >
      </el-pagination>

      <el-dialog title="证书打印" :visible.sync="addDdialog.show" :close-on-click-modal="false" @close="userClearSubmit">
        <el-form ref="userItem" :model="addDdialog" class="demo-form-inline" label-width="100px" >
          <el-form-item label="分隔数量" prop="number">
            <el-input placeholder="请输入分隔数量" type="number" v-model="addDdialog.number"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer">
          <el-button type="" size="medium" @click.native.prevent="addDdialog.show = false">取 消</el-button>
          <el-button type="primary" size="medium" :loading="addDdialog.loading" @click.native.prevent="userSubmit()">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>


<script>
import {
  // 证书管理
  certificateList,
} from "@/http/api/InitiationApi";

import { FileDownload2 } from "@/http/api/FileApi";

export default {
  name: "",
  props: {},
  components: {},
  data() {
    return {
      addDdialog: {
        number: '',
        loading: false,
        show: false,
        id: '',
        type: ''
      },
      creatorList: [
        {
          value: 1,
          name: "会员",
        },
        {
          value: 2,
          name: "理事",
        },
        {
          value: 3,
          name: "常务理事",
        },
        {
          value: 4,
          name: "副会长",
        },
      ],
      articleList: [],
      loading: false,
      pageSize: 20,
      pageNo: 1,
      totalPage: 1,
      totalCount: 1,
      searchData: {
        form: 0,
        isValid: 0,
        keyword: "",
        orgLevel: 0,
        plenaryLevel: "",
      },
    };
  },

  methods: {
    userClearSubmit() {
      this.$refs.userItem.resetFields()
      this.addDdialog.show = false
      this.addDdialog.loading = false
      this.addDdialog.number = ''
    },
    userSubmit() {
      const params = {
        id: this.addDdialog.id,
        number: this.addDdialog.number || 0
      }
      if (this.addDdialog.type == 1) {
        FileDownload2("/member/org/pdf/unit/cert/print", params);
      } else if (this.addDdialog.type == 2) {
        FileDownload2("/member/org/pdf/unit/cert/behalf/print", params);
      }
      this.addDdialog.show = false
      // this.$refs.userItem.validate((valid) => {
      //   if (!valid) return  false
      //   const params = {
      //     certificateExpireTime: this.addDdialog.value[1].substring(0, 4),
      //     id: this.addDdialog.id,
      //     payFeeTime: this.addDdialog.value[0]
      //   }
      //   FileDownload2("/member/manager/pdf/unit/cert/personal/print", params)
      //   this.addDdialog.show = false
      //   console.log(this.addDdialog.value)
      // })
    },
    detail(row, index) {
      if (index === 1 || index === 2) {
        this.addDdialog.show = true
        this.addDdialog.type = index
        this.addDdialog.id = row.id
        // FileDownload2("/member/manager/pdf/unit/cert/print", params);
      } else if (index === 3) {
        const params = {
          id: row.id
        }
        FileDownload2("/member/i/manager/member/info/export/address", params);
      }
    },
    // 搜索
    search() {
      this.getList();
    },
    handleSizeChange(val) {
      console.log(val);
    },
    handleCurrentChange(val) {
      console.log(val);
    },
    getList() {
      this.loading = true;
      certificateList({
        //  form (integer, optional): 类型 1.个人会员 2.单位会员 ,
        // isAsc (boolean, optional): 是否为升序true:升序false:降序，默认false ,
        // isValid (integer, optional): 是否有效期 1.是 2.否 ,
        // keyword (string, optional): 关键字 ,
        // orderBy (string, optional): 排序字段，默认create_date ,
        // orgLevel (integer, optional): 机构等级 1.会员 2.理事 3.常务理事 4.副会长 ,
        // pageNo (integer, optional): 当前页码，默认1 ,
        // pageSize (integer, optional): 页面大小，默认20 ,
        // plenaryLevel (integer, optional): 大会等级 1.会员 2.理事 3.常务理事 4.副会长
        pageSize: this.pageSize,
        pageNo: this.pageNo,
        form: 2,
        isValid: this.searchData.isValid,
        keyword: this.searchData.keyword,
        plenaryLevel: this.searchData.plenaryLevel,
        // orgLevel: this.searchData.orgLevel,
      }).then((res) => {
        this.loading = false;
        console.log(res);
        if (res.code === "000") {
          this.articleList = res.result.records;
        }
      });
    },
  },
  created() {
    this.getList();
  },
};
</script>

<style lang="less" scoped>
</style>
